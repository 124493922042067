<template>
    <section>
        <div class="container">
            <h1>{{ page_data.title }}</h1>
            <div class="content" v-html="page_data.content"></div>
        </div>
    </section>
</template>
<style scoped>
h1 {
    margin-bottom: 200px;
}

@media screen and (max-width: 1500px) {
    h1 {
        /* font-size: 100px; */
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 1100px) {
    h1 {
        /* font-size: 80px; */
        margin-bottom: 80px;
    }
}

@media screen and (max-width: 850px) {
    h1 {
        /* font-size: 60px; */
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 650px) {
    h1 {
        /* font-size: 40px; */
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 450px) {
    h1 {
        /* font-size: 34px; */
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 380px) {
    h1 {
        margin-bottom: 50px;
    }
}

</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    methods: {
        ...mapActions({
            getPageData: 'page/getPageData',
        }),
    },
    computed: {
        ...mapState({
            page_data: state => state.page.page_data,
        })
    },
    async created() {
        await this.getPageData('page/' + this.$route.path);
    }
}
</script>
