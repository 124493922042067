<template>
    <div class="slider">
        <div class="button-prev">prev</div>
        <swiper-container init="false">
            <swiper-slide v-for="slide in slides">
                <div class="header">
                    Заголовок
                </div>
                <span>
                    {{ slide }}
                </span>
            </swiper-slide>
        </swiper-container>
        <div class="button-next">next</div>
    </div>
    <div class="slider-pagination"></div>
</template>

<style>
.slider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
}

swiper-container {
    display: block;
    position: relative;
    max-width: calc(100% - 2 * (40px + 40px))
}

.button-prev,
.button-next {
    position: relative;
    width: 40px;
    cursor: pointer;
} 

.swiper-button-disabled {
    opacity: 0.4;
    pointer-events: none;
}

.slider-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: 0.5s;
}

.swiper-pagination-bullet {
    border-radius: 50%;
    background-color: darkgray;
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: black;
}
</style>

<script>
import { register } from 'swiper/element/bundle';

export default {
    data() {
        return {
            slides: ['1', '2', '3', '4', '5']
        }
    },
    mounted() {
        register();
        
        // swiper element
        const swiperEl = document.querySelector('swiper-container');

        // swiper parameters
        const swiperParams = {
            slidesPerView: 1,
            navigation: {
                nextEl: '.button-next',
                prevEl: '.button-prev',
            },
            pagination: {
                el: '.slider-pagination',
                clickable: true,
            },
            breakpoints: {
                640: {
                slidesPerView: 2,
                },
                1024: {
                slidesPerView: 3,
                },
            },
        };

        // now we need to assign all parameters to Swiper element
        Object.assign(swiperEl, swiperParams);

        // and now initialize it
        swiperEl.initialize();
    }
}
</script>