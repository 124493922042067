<template>
    <div :class="'preloader ' + ($store.state.page.loading ? 'active' : '')">
        <slot>
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </slot>
    </div>
</template>

<style src="@/assets/css/base/preloader.css" />

<script />