<template>
	<div class="more-container">
		<Card v-for="item in cards">
			<h3>{{ item.title }}</h3>
			<p>{{ item.content }}</p>
		</Card>
	</div>
	<MoreBtn step=3 @loadMore="show" :cards_length=this.cards.length>
		Показать ещё
	</MoreBtn>
</template>
<script>
import MoreBtn from '@/components/base/load_more/MoreBtn.vue';
import Card from '@/components/base/load_more/Card.vue';

export default {
	components: {
		Card,
		MoreBtn
	}, 
	data() {
		return {
			cards: [
				{ title: 'Первая карточка', content: 'Первая Первая Первая Первая Первая Первая Первая' },
				{ title: 'Вторая карточка', content: 'Вторая Вторая Вторая Вторая Вторая Вторая Вторая' },
				{ title: 'Третья карточка', content: 'Третья Третья Третья Третья Третья Третья Третья' }
			]
		}
	},
	methods: {
		show(new_cards){
            if (new_cards) {
                this.cards.push(new_cards);
            }
        }
	}
}
</script>
<style></style>