<template>
    <div class="drop-list">
        <DropItem :is_toggle_drop = drop v-for="item in items" :header = item.header>
            {{ item.text }}
        </DropItem>
    </div>
</template>
<script>

import DropItem from '@/components/base/DropItem.vue';

export default {
    props: {
        is_toggle_drop: "false",
    },
    components: {
        DropItem
    },
    data () {
        return {
            drop: this.is_toggle_drop,
            items: [
                {header: 'Заголовок 1', text: 'Дроп 1'},
                {header: 'Заголовок 2', text: 'Дроп 2'},
                {header: 'Заголовок 3', text: 'Дроп 3'},
            ]
        }
    }
}
</script>
<style>
    .drop-list {
        width: 100%;
    }
</style>