<template>
    <div class="card">
        <slot>

        </slot>
    </div>
</template>
<script>

</script>
<style>
    .card {
        padding: 20px;
        border-radius: 20px;
        background-color: antiquewhite;
        border: 1px solid black;
    }
</style>