<template>
    <Tabs>
        <template v-slot:nav>
            <div class="nav-item" v-for="item in items">
                {{ item.title }}
            </div>
        </template>
            <div class="content-item" v-for="item in items">
                {{ item.content }}
            </div>
    </Tabs>
</template>
<script>
    import Tabs from '@/components/base/Tabs.vue';
    export default {
        components: {
            Tabs
        },
        data() {
        return {
            items: [
                {title: 'Первая кнопка', content: 'Первая Первая Первая Первая Первая Первая Первая'},
                {title: 'Вторая кнопка', content: 'Вторая Вторая Вторая Вторая Вторая Вторая Вторая'},
                {title: 'Третья кнопка', content: 'Третья Третья Третья Третья Третья Третья Третья'}
            ],
            
        }
    },
    }
</script>
<style>

</style>