<template>
    <div class="drop-container">
        <div class="drop-header" @click="openDropItem()">
            <p>{{ header }}</p>
            <div class="drop-btn" v-if="is_toggle_drop == 'true'" ref="btn">
                <span class="btn-line"></span>
                <span class="btn-line plus-line"></span>
            </div>
        </div>
        <div class="drop-item" ref="drop_item">
            <slot>
                
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        is_toggle_drop: "false",
        header: '',
        text: ''
    },
    data () {
        return {
            is_drop: false
        }
    },
    methods: {
        openDropItem(){
            const drop_item = this.$refs.drop_item,
                  drop_height = drop_item.scrollHeight,
                  drop_btn = this.$refs.btn,
                  list = drop_item.closest('.drop-list');
            if (drop_item.style.maxHeight == '') {
                list.querySelectorAll('.drop-container').forEach(el => {
                    if (el.querySelector('.drop-btn')) {
                        el.querySelector('.drop-btn').classList.remove('active');
                    }
                    el.querySelector('.drop-item').style.maxHeight = '';
                });
                drop_item.style.maxHeight = drop_height + 'px';
                if (drop_btn) {
                    drop_btn.classList.add('active');
                }
            } else {
                drop_item.style.maxHeight = '';
                if (drop_btn) {
                    drop_btn.classList.remove('active');
                }
            }
        }
    }
}
</script>
<style>
    .drop-container {
        width: 100%;
        max-width: 600px;
    }

    .drop-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid red;
    }

    .drop-btn {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        min-width: 42px;
        height: 42px;
        border-radius: 55px;
        background-color: transparent;
    }

    .drop-btn.active .btn-line.plus-line {
        transform: rotate(0deg);
    }

    .btn-line {
        transition: 0.4s;
        position: absolute;
        top: 20px;
        left: 13px;
        width: 17px;
        border-top: 1px solid #6055C0;
    }

    .btn-line.plus-line {
        transform: rotate(90deg);
    }

    .drop-item {
        max-height: 0px;
        overflow: hidden;
        transition: 0.8s;
    }

    .drop-header p {
        padding: 20px 0px;
        margin: 0px;
    }
</style>