<template>
    <noindex>
        <section id="main">
            <div class="container">
                <h1>Компоненты</h1>
                <ul>
                    <li><a href="#slider">Слайдер</a></li>
                    <li><a href="#popup">Попап</a></li>
                    <li><a href="#drop-list">Список выпадающий</a></li>
                    <li><a href="#form">Форма</a></li>
                    <li><a href="#tabs">Вкладки</a></li>
                    <li><a href="#show-more">Показать еще</a></li>
                    <li><a href="#table">Таблица</a></li>
                </ul>
            </div>
        </section>
        <section id="slider">
            <div class="container">
                <h2>Слайдер</h2>
                <Slider/>
            </div>
        </section>
        <section id="popup">
            <div class="container">
                <h2>Попап</h2>
                <ExamplesPopup/>
            </div>
        </section>
        <section id="drop-list">
            <div class="container">
                <h2>Список выпадающий</h2>
                <ExamplesDropList/>
            </div>
        </section>
        <section id="form">
            <div class="container">
                <h2>Форма</h2>
                <ExamplesForm/>
            </div>
        </section>
        <section id="tabs">
            <div class="container">
                <h2>Вкладки</h2>
                <ExamplesTabs/>
            </div>
        </section>
        <section id="show-more">
            <div class="container">
                <h2>Показать еще</h2>
                <MoreContainer/>
            </div>
        </section>
        <section id="table">
            <div class="container">
                <h2>Таблица</h2>
            </div>
        </section>
   </noindex>
</template>
<style src="@/assets/css/base/components.css">
</style>
<style>
    .more-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
</style>
<script>
// Libs
import SmoothScroll from 'smooth-scroll'

// Examples
import Slider from '@/components/examples/Slider.vue';
import ExamplesForm from '@/components/examples/ExamplesForm.vue';
import ExamplesPopup from '@/components/examples/ExamplesPopup.vue';
import ExamplesDropList from '@/components/examples/ExamplesDropList.vue';
import MoreContainer from '@/components/examples/MoreContainer.vue';
import ExamplesTabs from '@/components/examples/ExamplesTabs.vue';

// Base

// Vue
import { mapMutations } from 'vuex'

export default {
    name: 'ComponentsView',
    components: {
        Slider,
        ExamplesPopup,
        ExamplesDropList,
        ExamplesTabs,
        MoreContainer,
        ExamplesForm
    },
    methods: {
        ...mapMutations({
            setLoading: 'page/setLoading',
        }),
    },
    mounted() {
        // Убирает прелоадер. 
        setTimeout(() => {
            this.setLoading(false);
        }, 1500);

        // Активация плавного скролла.
        const scroll = new SmoothScroll('a[href*="#"]');
    }
}
</script>