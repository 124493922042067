<template>
    <div class="drop-list-container">
        <DropList is_toggle_drop = "true"></DropList>
        <DropList></DropList>
    </div>
</template>
<script>
    import DropList from '@/components/base/DropList.vue';
    export default {
        components: {
            DropList
        }
    }
</script>
<style>
    .drop-list-container {
        display: flex; 
        flex-direction: row;
         width: 100%;
        gap: 20px;
    }
</style>